import styled from "styled-components"
import { animated } from "react-spring"

export const WhatsAppWrapper = styled.div`
  height: 380px;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2000;
`

export const WhatsAppButton = styled(animated.button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  height: 55px;
  width: 55px;
  border-radius: 50%;
  background-color: #343434;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: none;

  :focus {
    outline: none;
  }

  :hover {
    transform: scale(1.1);
  }
`

export const ToolTip = styled(animated.div)`
  position: relative;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  z-index: 1000;
  box-shadow: 0 19px 44px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  & > p {
    margin: 0;
    padding: 40px 20px 0 20px;
  }
`

export const WhatsAppLink = styled.a`
  border: none;
  width: 240px;
  height: 40px;
  background-color: #ffffff;
  display: block;
  margin: 20px 30px;
  display: flex;
  padding: 0;

  & > img {
    margin-right: 20px;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
  }

  & > div {
    pointer-events: none;
  }

  & :hover {
    color: black;

    & > img {
      transform: scale(1.1);
    }
  }
`

export const JobTitle = styled.span`
  font-weight: bold;
  display: block;
  text-align: left;
  pointer-events: none;
`
export const Name = styled.span`
  display: block;
  text-align: left;
  pointer-events: none;
`

export const Triangle = styled.div`
  position: absolute;
  bottom: -10px;
  right: 20px;
  clip-path: polygon(100% 0, 0 0, 50% 100%);
  height: 10px;
  width: 20px;
  background-color: #ffffff;
  z-index: 1001;

  box-shadow: 0 19px 44px 0 rgba(0, 0, 0, 0.16);
`
