import React from "react"
import CookieConsent from "react-cookie-consent"

// Styled components
import { ConsentMessage } from "./cookie-consent.styles"

export default ({ staticText }) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText={staticText.acceptButton}
      cookieName="innerstrength-amsterdam"
      style={{
        background: "#393939",
        zIndex: "5000",
        display: "flex",
        alignItems: "center",
      }}
      buttonStyle={{
        backgroundColor: "green",
        fontWeight: "bold",
        height: "40px",
        minWidth: "150px",
        color: "white",
        fontSize: "0.8rem",
      }}
      expires={150}
    >
      <ConsentMessage style={{ lineHeight: "1.2" }}>
        {staticText.disclaimer}
      </ConsentMessage>
    </CookieConsent>
  )
}
