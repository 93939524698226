import styled from "styled-components"
import { greyFontColor, backgroundColor } from "../../../context/theme/theme"

export const FooterWrapper = styled.footer`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${backgroundColor};
`
export const FooterContainer = styled.div`
  width: 90%;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    font-size: 10px;
    font-weight: normal;
    font-style: normal;
    line-height: 1.7;
    letter-spacing: 0.21px;
    text-align: center;
    color: ${greyFontColor};
  }

  & > svg {
    fill: ${greyFontColor};
    margin: 10px 0;
    width: 200px;
    height: auto;
  }
`

export const FooterText = styled.ul`
  display: block;
  list-style-type: none;
  text-align: center;
  margin-top: 10px;
  padding: 0;

  & > li:first-child {
    font-weight: bold;
  }
  & > li {
    font-family: inherit;
    color: ${greyFontColor};
  }
`

export const LegalLinks = styled.div`
  margin-top: 40px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;

  & > a {
    color: ${greyFontColor};
    transition: color 0.3s ease-in-out;

    :hover {
      color: black;
    }
  }
`
