import styled from "styled-components"
import { backgroundColor } from "../../../context/theme/theme"

export const NavWrapper = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row-reverse;
  z-index: 2000;
  background-color: ${backgroundColor};

  @media (min-width: 1270px) {
    display: none;
  }
`
export const LogoWrapper = styled.div`
  position: absolute;
  top: 0px;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (min-width: 1270px) {
    display: none;
  }
`
