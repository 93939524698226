import React from "react"
import { BurgerWrapper, BurgerIcon, BurgerLine } from "./burger-styles"
import { useTrail } from "react-spring"

export const Burger = ({ toggle }) => {
  const trail = useTrail(3, {
    delay: !toggle ? 800 : 0,
    to: {
      width: !toggle ? "100%" : "0%",
      opacity: !toggle ? 1 : 0,
    },
    from: {
      width: !toggle ? "0%" : "100%",
      opacity: !toggle ? 0 : 1,
    },
  })

  return (
    <BurgerWrapper>
      <BurgerIcon>
        {trail.map((props, index) => (
          <BurgerLine key={index} style={props} />
        ))}
      </BurgerIcon>
    </BurgerWrapper>
  )
}
