import styled from "styled-components"
import { fontColor, backgroundColor } from "../../../context/theme/theme"
import { animated } from "react-spring"

export const NavWrapper = styled.div`
  display: none;

  @media (min-width: 1270px) {
    display: block;
    position: fixed;
    width: calc(100% - 80px);
    margin-left: 40px;
    height: 60px;
    top: 30px;
    color: ${fontColor};
    z-index: 5;
  }
`

export const LogoWrapper = styled(animated.div)`
  height: 60px;
  width: 100%;
  position: absolute;
  top: 10px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  & > a {
    z-index: 1000;
  }
`

export const NavBar = styled(animated.nav)`
  position: relative;
  min-width: 80px;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${backgroundColor};

  & > ul {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 1000;
    margin-left: 40px;
    & > li {
      & > a {
        color: ${fontColor};
        font-family: "HelveticaNeue Condensed";
        font-size: 14px;
        font-weight: bold;
        margin-right: 20px;
        z-index: 1000;
      }
    }
  }
`
export const SwitchWrapper = styled(animated.div)`
  min-width: 220px;
  height: 100%;
  display: flex;
  align-items: center;
`
