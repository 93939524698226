module.exports = {
  pathPrefix: "/", // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: "Personal Training | Inner strength Amsterdam", // Navigation and Site Title
  titleAlt: "Inner strength Amsterdam", // Title for JSONLD
  description:
    "Personal Training in Amsterdam. 7 dagen per week trainen met 2 dedicated personal trainers. Boek je intake online. Let us find YOUR Inner Strength!",
  headline: "Inner strength Amsterdam | Personal Training Amsterdam", // Headline for schema.org JSONLD
  url: "https://innerstrengthamsterdam.nl", // Domain of your site. No trailing slash!
  logo: "src/images/isa-favicon.png", // Used for SEO
  ogLanguage: "nl_NL", // Facebook Language

  // JSONLD / Manifest
  favicon: "src/images/isa-favicon.png", // Used for manifest favicon generation
  shortName: "Inner strength Amsterdam", // shortname for manifest. MUST be shorter than 12 characters
  company: "Inner strength Amsterdam", // Author for schemaORGJSONLD
  themeColor: "#000000",
  backgroundColor: "#ffffff ",

  googleAnalyticsID: "UA-XXXXXX-X",
  skipNavId: "reach-skip-nav", // ID for the "Skip to content" a11y feature
}
