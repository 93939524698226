import React, { useState, useRef, useEffect } from "react"

import { animated, useTrail, useSpring } from "react-spring"
import { Waypoint } from "react-waypoint"

import { NavWrapper, NavBar, LogoWrapper, SwitchWrapper } from "./nav-styles"
import { Logo } from "../../Logo"
import { LocaleSwitch } from "../LocaleSwitch"
import { Burger } from "../Burger"
import { LocaleContext } from "../Layout"
import i18n from "../../../../config/i18n"
import { AnchorLink } from "gatsby-plugin-anchor-links"

export const Nav = ({ location }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const navItems = i18n[locale].navItems

  const [toggle, set] = useState(true)

  const navRef = useRef()

  const handleClick = e => {
    if (navRef.current.contains(e.target)) {
      return
    }
    set(false)
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick)

    return () => {
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  function toggleNav(state) {
    if (toggle !== state) {
      set(state)
    }
    return
  }

  const navAnimation = useSpring({
    delay: toggle ? 0 : 800,
    duration: 500,
    from: { width: toggle ? "0%" : "100%" },
    to: { width: toggle ? "100%" : "0%" },
  })

  const trail = useTrail(navItems.length, {
    delay: toggle ? 800 : 0,
    to: {
      transform: toggle ? "translate3d(0,0px,0)" : "translate3d(0,10px,0)",
      opacity: toggle ? 1 : 0,
    },
    from: {
      transform: toggle ? "translate3d(0,10px,0)" : "translate3d(0,0px,0)",
      opacity: toggle ? 0 : 1,
    },
  })

  const switchAnimation = useSpring({
    delay: toggle ? 800 : 0,
    to: {
      transform: toggle ? "translate3d(0,0px,0)" : "translate3d(0,10px,0)",
      opacity: toggle ? 1 : 0,
    },
    from: {
      transform: toggle ? "translate3d(0,10px,0)" : "translate3d(0,0px,0)",
      opacity: toggle ? 0 : 1,
    },
  })

  const logoAnimation = useSpring({
    delay: toggle ? 800 : 0,
    to: {
      opacity: toggle ? 1 : 0,
    },
    from: {
      opacity: toggle ? 0 : 1,
    },
  })

  return (
    <>
      <Waypoint
        onLeave={() => toggleNav(false)}
        onEnter={() => toggleNav(true)}
      />
      <NavWrapper ref={navRef}>
        <NavBar onClick={() => set(true)} style={navAnimation}>
          <Burger toggle={toggle} />
          <ul>
            {trail.map((props, index) => (
              <animated.li key={index} style={props}>
                <AnchorLink
                  to={navItems[index].path}
                  title={navItems[index].name}
                >
                  {navItems[index].name}
                </AnchorLink>
              </animated.li>
            ))}
          </ul>
          <LogoWrapper style={logoAnimation}>
            <AnchorLink to="/">
              <Logo />
            </AnchorLink>
          </LogoWrapper>
          <SwitchWrapper style={switchAnimation}>
            <LocaleSwitch lang={lang} />
          </SwitchWrapper>
        </NavBar>
      </NavWrapper>
    </>
  )
}

export default Nav
