import React from "react"
import { ThemeProvider } from "styled-components"

// toggle context, with a default empty toggle function
const ThemeSetContext = React.createContext({
  setTheme: () => {},
  getTheme: () => {},
})

// exportable useContext hook object
export const useTheme = () => React.useContext(ThemeSetContext)

// render both contexts, then Wrapper, then children
export const MyThemeProvider = ({ children }) => {
  const [themeState, setThemeState] = React.useState({
    mode: "white",
  })

  const setTheme = theme => {
    const themeColor = theme ? theme : "white"
    setThemeState({ mode: themeColor })
  }

  return (
    <ThemeSetContext.Provider value={{ setTheme: setTheme }}>
      <ThemeProvider
        theme={{
          mode: themeState.mode,
        }}
      >
        {children}
      </ThemeProvider>
    </ThemeSetContext.Provider>
  )
}

export default MyThemeProvider
