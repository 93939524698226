import React from "react"
import i18n from "../../../config/i18n"
import { useStaticQuery, graphql } from "gatsby"
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav"

import { GlobalStyle } from "../../styles/theme"
import { MyThemeProvider } from "../../context/theme/ThemeContext"
import { Nav } from "./Nav"
import { NavMobile } from "./NavMobile"
import { Footer } from "./Footer"
import { WhatsApp } from "./WhatsApp"
import { CookieConsent } from "./CookieConsent"

export const LocaleContext = React.createContext("")

export const Layout = ({ children, pageContext: { locale }, location }) => {
  const data = useStaticQuery(query)
  const staticText = i18n[locale]

  return (
    <>
      <LocaleContext.Provider value={{ locale, i18n }}>
        <MyThemeProvider>
          <GlobalStyle />
          <SkipNavLink />
          <Nav location={location} />
          <NavMobile />
          <SkipNavContent />
          {children}
          <WhatsApp staticText={staticText.whatsApp} />
          <Footer />
          <CookieConsent staticText={staticText.cookieConsent} />
        </MyThemeProvider>
      </LocaleContext.Provider>
    </>
  )
}

const query = graphql`
  query LayoutQuery {
    allPrismicHomepage {
      edges {
        node {
          lang
        }
      }
    }
  }
`
