import styled from "styled-components"
import { animated } from "react-spring"
import { fontColor, backgroundColor } from "../../../context/theme/theme"

export const BurgerWrapper = styled.button`
  position: absolute;
  height: 50px;
  min-width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: none;
  z-index: 20;

  @media (min-width: 1270px) {
    height: 80px;
    min-width: 80px;
  }

  &:focus {
    outline-width: 0;
  }
`

export const BurgerIcon = styled.div`
  width: 25px;
  height: auto;
  display: flex;
  flex-direction: column;

  & > * + * {
    margin-top: 0.3rem;
  }
`
export const BurgerLine = styled(animated.span)`
  height: 3px;
  width: auto;
  background-color: ${fontColor};
`
