import styled from "styled-components"
import { animated } from "react-spring"
import { greyFontColor } from "../../../../context/theme/theme"

export const StyledMenu = styled(animated.div)`
  position: fixed;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background-color: #ffffff;
  z-index: 2000;
`
export const MenuHeader = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: "space-between";
`

export const CloseButton = styled(animated.button)`
  display: block;
  height: 50px;
  width: 50px;
  margin: 15px;
  background-color: transparent;
  border: none;
  & > img {
    width: 100%;
  }
`
export const NavItems = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;

  & > li {
    margin: 50px 0;
    padding: 0;
    padding-left: 20px;
    font-family: "HelveticaNeue Condensed";
    font-size: 1rem;
    letter-spacing: 7.95px;
  }
`
export const MenuFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  flex-direction: column;
  bottom: 20px;

  & > svg {
    fill: ${greyFontColor};
    margin: 10px 0;
    width: 120px;
    height: auto;
  }
`
export const SwitchWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  color: ${greyFontColor};
  & * {
    font-weight: normal;
  }
`
