import { createGlobalStyle } from "styled-components"

export const GlobalStyle = createGlobalStyle`
  * {
    font-family: "HelveticaNeue Condensed", Arial, sans-serif;
  }
  body {
    padding: 0;
    margin: 0;
    font-family: "HelveticaNeue Condensed", Arial, sans-serif;
    overflow-x: hidden; 
  }

  h1 {
    font-family: "HelveticaNeue Condensed";
    font-size: 2.5em;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: 34.08px;
    text-align: left;
    color: #393939;
  }

  h2 {
    font-family: "HelveticaNeue Condensed";
    font-size: 2em;
    line-height: 1;
    font-weight: bold;
    font-stretch: condensed;
    font-style: normal;
    letter-spacing: 14px;
    text-align: left;
    color: #ffffff;
  }

  p {
    font-family: "Roboto light";
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.25;
    letter-spacing: normal;
    text-align: left;
    color: #393939;
  }
  a {
    text-decoration: none;
    color: #393939;
  }
  ul {
    margin: 0 auto;
    list-style-type: none;
    font-family: "Roboto";
    font-size: 13px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #393939;
  }
  [data-reach-skip-link] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
  }
  [data-reach-skip-link]:focus {
    padding: 5px;
    position: fixed;
    top: 10px;
    left: 10px;
    background: transparent;
    z-index: 10;
    width: auto;
    height: auto;
    clip: auto;
  }
      /* width */
    ::-webkit-scrollbar {
      width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background:  none;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #393939;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #393939;
    }
`
