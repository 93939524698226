import LocaleWrap from "./wrapPageElement"

//https://medium.com/@sergeybulavyk/mastering-your-fonts-in-gatsby-fbdf2782e083
import "./src/fonts/fonts.css"

export const wrapPageElement = LocaleWrap

export const onClientEntry = () => {
  const addJS = () => {
    const s = document.createElement(`script`)
    s.type = `text/javascript`
    s.src = "https://assets.calendly.com/assets/external/widget.js"
    document.getElementsByTagName(`head`)[0].appendChild(s)
  }
  addJS()
}
