import React, { useState, Component, useContext } from "react"
import Switch from "react-switch"
import styled from "styled-components"
import { navigate } from "gatsby"

export class LocaleSwitch extends Component {
  constructor() {
    super()
    this.state = { checked: false }
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount() {
    if (this.props.lang.locale === "en-gb") {
      this.setState({ checked: true })
    }
  }

  handleChange(checked) {
    this.setState({ checked })
    const { locale } = this.props.lang
    if (checked && locale != "en-gb") {
      navigate("/en")
    } else if (!checked && locale != "nl-nl") {
      navigate("/")
    } else {
      return
    }
  }

  render() {
    return (
      <>
        <SwitchLabel
          onClick={() => this.handleChange(false)}
          aria-label="switch website's language to dutch"
        >
          NL
        </SwitchLabel>
        <Switch
          height={25}
          width={48}
          onChange={this.handleChange}
          checked={this.state.checked}
          uncheckedIcon={false}
          checkedIcon={false}
          offColor="#393939"
          onColor="#38a5d6"
          aria-label="toggle to switch website to another language"
        />
        <SwitchLabel
          onClick={() => this.handleChange(true)}
          aria-label="switch website's language to english"
        >
          EN
        </SwitchLabel>
      </>
    )
  }
}

const SwitchLabel = styled.button`
  font-family: "HelveticaNeue Condensed";
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  margin: 0 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
`
