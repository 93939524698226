module.exports = [{
      plugin: require('/vercel/6881db9a/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/6881db9a/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/6881db9a/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Personal Training | Inner strength Amsterdam","short_name":"Inner strength Amsterdam","description":"Personal Training in Amsterdam. 7 dagen per week trainen met 2 dedicated personal trainers. Boek je intake online. Let us find YOUR Inner Strength!","start_url":"","background_color":"#ffffff ","theme_color":"#000000","display":"standalone","icon":"src/images/isa-favicon.png"},
    },{
      plugin: require('/vercel/6881db9a/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
