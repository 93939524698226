import React, { useState } from "react"
import {
  WhatsAppWrapper,
  WhatsAppButton,
  ToolTip,
  WhatsAppLink,
  JobTitle,
  Name,
  Triangle,
} from "./whatsapp.styles"
import { useSpring } from "react-spring"

import whatsapp from "../../../images/whatsapp-icon-white.svg"
import whatsappBlack from "../../../images/whatsapp-icon-black.svg"

export const WhatsApp = ({ staticText }) => {
  const [toggle, setToggle] = useState(false)

  const animation = useSpring({
    to: { opacity: toggle ? 1 : 0, display: toggle ? "block" : "none" },
  })

  return (
    <>
      <WhatsAppWrapper onMouseLeave={() => setToggle(false)}>
        <ToolTip style={animation}>
          <p>👋 {staticText.description}</p>
          <WhatsAppLink
            href={`https://api.whatsapp.com/send?phone=+31612382935&text=${staticText.defaultMessage}`}
          >
            <img src={whatsappBlack} />
            <div>
              <JobTitle>Personal Trainer</JobTitle>
              <Name>Xavier</Name>
            </div>
          </WhatsAppLink>
          <WhatsAppLink
            href={`https://api.whatsapp.com/send?phone=+31641568852&text=${staticText.defaultMessage}`}
          >
            <img src={whatsappBlack} />
            <div>
              <JobTitle>Personal Trainer</JobTitle>
              <Name>Marco</Name>
            </div>
          </WhatsAppLink>
          <Triangle />
        </ToolTip>
        <WhatsAppButton onClick={() => setToggle(!toggle)}>
          <img src={whatsapp} alt="whatsapp" />
        </WhatsAppButton>
      </WhatsAppWrapper>
    </>
  )
}

export default WhatsApp
