const website = require("./website")

module.exports = {
  "nl-nl": {
    default: true,
    path: "nl",
    locale: "nl-nl",
    siteLanguage: "nl",
    ogLang: "nl_NL",
    defaultTitle: website.title,
    defaultTitleAlt: website.titleAlt,
    defaultDescription: website.description,
    headline: website.headline,
    blog: {
      metaTitle: "Onze verhalen | Inner strength Amsterdam",
      metaDescription:
        "Personal Training in Amsterdam. 7 days per week with 2 dedicated personal trainers. schedule your intake online. Let us find YOUR Inner Strength!",
      heading: "Onze verhalen",
    },
    navItems: [
      {
        name: "OVER ONS",
        path: "/#over-ons",
      },
      {
        name: "PERSONAL TRAINING",
        path: "/#innerstrength-personal-training",
      },
      {
        name: "THE INNERSTRENGTH WAY",
        path: "/#innerstrength-way",
      },
      {
        name: "VERHALEN",
        path: "/#verhalen",
      },
    ],
    reviews: {
      readMore: "Lees verder",
      readLess: "Terug",
      readReviews: "LEES ALLE REVIEWS",
      leaveReview: "REVIEW PLAATSEN",
    },
    stories: {
      id: "verhalen",
      heading: "<h3>ONZE LAATSTE<br />VERHALEN</h3>",
      label: "VERHAAL: ",
      readNow: "<span>LEES<br />NU</span>",
    },
    intakeForm: {
      heading: "<h3>EEN INTAKE PLANNEN?</h3>",
      formHeading: "<h3>PLAN INTAKE</h3>",
      radioButtonText: "Kies een dienst: ",
      formText: "Contact gegevens: ",
      intakeText:
        "Laat hieronder jouw gegevens achter daarna nemen wij zo snel mogelijk contact met je op.",
      placeholderName: "Naam",
      placeholderEmail: "E-mail adres",
      placeholderPhone: "Telefoonnummer",
      privacyPolicyText: "Ik ga akkoord met de ",
      buttonText: "VERSTUUR AANVRAAG",
      errorName: "Naam is een verplicht veld",
      errorEmailReq: "E-mail is een verplicht veld",
      errorEmailExample: "Probeer dit:  naam@voorbeelmail.com",
      errorPhone: "Dit telefoonnummer is niet valide",
      confirmationVideoId: "Oo_Lf1_TGZE",
      anchorLink: "/#plan-intake",
    },
    whatsApp: {
      description:
        "Hoi! Je kunt ons altijd bereiken via whatsapp klik op een van onderstaande trainers. ",
      defaultMessage: "Ik heb een vraag over... ",
    },
    legalItems: {
      termsAndConditions: {
        name: "algemene voorwaarden",
        path: "/terms-and-conditions",
      },
      privacyStatement: {
        name: "privacy statement",
        path: "/privacy-statement",
      },
    },
    cookieConsent: {
      disclaimer:
        "We gebruiken cookies om content en advertenties te personaliseren, om functies voor social media te bieden en om ons websiteverkeer te analyseren. Ook delen we informatie over uw gebruik van onze site met onze partners voor social media, adverteren en analyse. Deze partners kunnen deze gegevens combineren met andere informatie die u aan ze heeft verstrekt of die ze hebben verzameld op basis van uw gebruik van hun services. U gaat akkoord met onze cookies als u onze website blijft gebruiken.",
      acceptButton: "COOKIES TOESTAAN",
    },
  },
  "en-gb": {
    path: "en",
    locale: "en-gb",
    siteLanguage: "en",
    ogLang: "en_GB",
    defaultTitle: "Personal Training | Inner strength Amsterdam",
    defaultTitleAlt: "Inner strength Amsterdam",
    defaultDescription:
      "Personal Training in Amsterdam. 7 days per week with 2 dedicated personal trainers. schedule your intake online. Let us find YOUR Inner Strength!",
    headline: "Inner strength Amsterdam | Personal Training Amsterdam",
    blog: {
      metaTitle: "Our stories | Inner strength Amsterdam",
      metaDescription:
        "Personal Training in Amsterdam. 7 days per week with 2 dedicated personal trainers. schedule your intake online. Let us find YOUR Inner Strength!",
      heading: "Our stories",
    },
    navItems: [
      {
        name: "ABOUT",
        path: "en/#more-about",
      },
      {
        name: "PERSONAL TRAINING",
        path: "en/#innerstrength-personal-training",
      },
      {
        name: "THE INNERSTRENGTH WAY",
        path: "en/#innerstrength-way",
      },
      {
        name: "STORIES",
        path: "en/#stories",
      },
    ],
    reviews: {
      readMore: "Read more",
      readLess: "Back",
      readReviews: "READ ALL REVIEWS",
      leaveReview: "LEAVE REVIEW",
    },
    stories: {
      id: "stories",
      heading: "<h3>OUR LATEST<br />STORIES</h3>",
      label: "STORY",
      readNow: "<span>READ<br />NOW</span>",
    },
    intakeForm: {
      heading: "<h3>PLAN AN INTAKE?</h3>",
      formHeading: "<h3>PLAN INTAKE</h3>",
      radioButtonText: "Choose your way: ",
      formText: "Your info",
      intakeText:
        "Enter your contact details and we will get back to you as soon as possible.",
      placeholderName: "Name",
      placeholderEmail: "E-mail address",
      placeholderPhone: "Phone number",
      privacyPolicyText: "I agree to the ",
      buttonText: "SEND REQUEST",
      errorName: "Name is required",
      errorEmailReq: "Your e-mail is required",
      errorEmailExample: "Try this:  yourname@example.com",
      errorPhone: "Phone number is not valid",
      confirmationVideoId: "qr1AwkoOMow",
      anchorLink: "/en/#plan-intake",
    },
    whatsApp: {
      description:
        "Hi there! You can always contact us via whatsapp, just click on one of the trainers.",
      defaultMessage: "I would like to ask somehting... ",
    },
    legalItems: {
      termsAndConditions: {
        name: "terms & conditions",
        path: "/terms-and-conditions",
      },
      privacyStatement: {
        name: "privacy statement",
        path: "/privacy-statement",
      },
    },
    cookieConsent: {
      disclaimer:
        "We use cookies to personalize content and ads, to provide social media features and to analyze our website traffic. We also share information about your use of our site with our social media, advertising and analytics partners. These partners may combine this data with other information that you have provided to them or that they have collected from your use of their services. You agree to our cookies if you continue to use our website.",
      acceptButton: "ACCEPT COOKIES",
    },
  },
}
