import theme from "styled-theming"

export const backgroundColor = theme("mode", {
  white: "#ffffff",
  red: "#ff6e6e",
  blue: "#38a5d6",
})

export const fontColor = theme("mode", {
  white: "rgb(57, 57, 57)",
  red: "#ffffff",
  blue: "#ffffff",
})

export const greyFontColor = theme("mode", {
  white: "#b9b9b9",
  red: "#ffffff",
  blue: "#ffffff",
})
