import React, { useState } from "react"

import { LocaleContext } from "../Layout"
import i18n from "../../../../config/i18n"

import { Burger } from "../Burger"
import { NavWrapper, LogoWrapper } from "./navmobile-styles"
import { Menu } from "./Menu"
import { Logo } from "../../Logo"
import { LocalizedLink } from "../../Primitives/LocalizedLink"

export const NavMobile = ({ props }) => {
  const lang = React.useContext(LocaleContext)
  const { locale } = lang
  const navItems = i18n[locale].navItems

  const [toggle, set] = useState(false)

  function toggleNav(state) {
    if (toggle !== state) {
      set(state)
    } else {
      set(!state)
    }
    return
  }

  return (
    <>
      <LogoWrapper>
        <LocalizedLink to="/">
          <Logo />
        </LocalizedLink>
      </LogoWrapper>
      <NavWrapper onClick={() => toggleNav(true)}>
        <Burger toggle={toggle} />
      </NavWrapper>
      <Menu toggle={toggle} toggleNav={toggleNav} navItems={navItems} />
    </>
  )
}

export default NavMobile
