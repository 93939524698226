import React from "react"
import { Link } from "gatsby"
import { greyFontColor } from "../../../../context/theme/theme"

import styled from "styled-components"

const SocialsContainer = styled.div`
  margin: 10px 0;
  width: 50px;
  display: flex;
  justify-content: space-between;

  & svg {
    fill: ${greyFontColor};
  }
`

export const Socials = () => {
  return (
    <SocialsContainer>
      <Link to="/">
        <svg
          width="22px"
          height="21px"
          viewBox="0 0 22 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Group_7818</title>
          <desc>Created with Sketch.</desc>
          <g id="Page-1" stroke="none" strokeWidth="1">
            <g id="group-7819" fillRule="nonzero">
              <g id="Group_7818">
                <g id="Group_7817">
                  <g id="Group_7814">
                    <path
                      d="M14.698,20.285 L6.555,20.285 C2.93676328,20.2805909 0.00485706305,17.3482373 0.001,13.73 L0.001,6.554 C0.00540798155,2.93615317 2.93715317,0.00440798155 6.555,-2.84217094e-14 L14.698,-2.84217094e-14 C18.3158468,0.00440798155 21.247592,2.93615317 21.252,6.554 L21.252,13.73 C21.2481429,17.3482373 18.3162367,20.2805909 14.698,20.285 L14.698,20.285 Z M6.555,1.457 C3.73834778,1.457 1.455,3.74034778 1.455,6.557 L1.455,13.733 C1.455,15.0856041 1.99231989,16.3828091 2.94875542,17.3392446 C3.90519095,18.2956801 5.2023959,18.833 6.555,18.833 L14.698,18.833 C17.5146522,18.833 19.798,16.5496522 19.798,13.733 L19.798,6.557 C19.798,3.74034778 17.5146522,1.457 14.698,1.457 L6.555,1.457 Z"
                      id="Path_841"
                    ></path>
                  </g>
                  <g id="Group_7815" transform="translate(4.792000, 4.308000)">
                    <path
                      d="M5.834,11.669 C2.6135698,11.6651429 0.00385713673,9.0554302 0,5.835 C0.00330719928,2.61434165 2.61334165,0.00430719928 5.834,0.001 C9.05465835,0.00430719928 11.6646928,2.61434165 11.668,5.835 C11.6641429,9.0554302 9.0544302,11.6651429 5.834,11.669 L5.834,11.669 Z M5.834,1.456 C3.41723961,1.4587554 1.4587554,3.41723961 1.456,5.834 C1.4587554,8.25076039 3.41723961,10.2092446 5.834,10.212 C8.25076039,10.2092446 10.2092446,8.25076039 10.212,5.834 C10.2092446,3.41723961 8.25076039,1.4587554 5.834,1.456 L5.834,1.456 Z"
                      id="Path_842"
                    ></path>
                  </g>
                  <g id="Group_7816" transform="translate(15.160000, 3.277000)">
                    <path
                      d="M2.421,1.211 C2.421,1.87926455 1.87926455,2.421 1.211,2.421 C0.542573498,2.421 0.00055196243,1.87942627 5.68434189e-14,1.211 C5.68434189e-14,0.542183168 0.542183168,5.68434189e-14 1.211,5.68434189e-14 C1.87942627,0.00055196243 2.421,0.542573498 2.421,1.211 L2.421,1.211 Z"
                      id="Path_843"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Link>
      <Link to="/">
        <svg
          width="11px"
          height="21px"
          viewBox="0 0 11 21"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title>Path_844</title>
          <desc>Created with Sketch.</desc>
          <g id="Page-1" stroke="none" strokeWidth="1">
            <g
              id="group-7819"
              transform="translate(-41.000000, 0.000000)"
              fillRule="nonzero"
            >
              <path
                d="M48.149,20.286 L48.149,11.033 L51.255,11.033 L51.72,7.427 L48.149,7.427 L48.149,5.127 C48.149,4.083 48.439,3.372 49.936,3.372 L51.845,3.372 L51.845,0.146 C50.9205883,0.0483166947 49.9915534,0.000913545619 49.062,0.00385848666 C46.309,0.00385848666 44.424,1.684 44.424,4.771 L44.424,7.43 L41.306,7.43 L41.306,11.036 L44.419,11.036 L44.419,20.289 L48.149,20.286 Z"
                id="Path_844"
              ></path>
            </g>
          </g>
        </svg>
      </Link>
    </SocialsContainer>
  )
}

export default Socials
