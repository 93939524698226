import React from "react"

import { Layout } from "./src/components/Layout/Layout"
import { Helmet } from "react-helmet"

const wrapPageElement = ({ element, props }) => (
  <Layout {...props}>{element}</Layout>
)

export default wrapPageElement
