// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-post-js": () => import("/vercel/6881db9a/src/templates/BlogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-service-page-js": () => import("/vercel/6881db9a/src/templates/ServicePage.js" /* webpackChunkName: "component---src-templates-service-page-js" */),
  "component---src-templates-landing-page-js": () => import("/vercel/6881db9a/src/templates/LandingPage.js" /* webpackChunkName: "component---src-templates-landing-page-js" */),
  "component---src-pages-404-js": () => import("/vercel/6881db9a/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("/vercel/6881db9a/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-index-js": () => import("/vercel/6881db9a/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

